export function getSizeStyle(status, size, selectedUserSize){
    switch (status) {
        case "othersDeclinations":
            return {border: "solid 2px #FDB52F", cursor: "pointer", borderRadius: "5px", opacity: selectedUserSize && size && selectedUserSize != size ? "0.2" : "1"}
        case "unavailable":
            return {border: "solid 2px grey", opacity: selectedUserSize && size && selectedUserSize != size ? "0.2" : "0.3", borderRadius: "5px"}
        case "available":
            return {border: "solid 2px #7ac066", cursor: "pointer", borderRadius: "5px", opacity: selectedUserSize && size && selectedUserSize != size ? "0.2" : "1"}
        case "othersRetailers":
            return {border: "solid 2px #FDB52F", cursor: "pointer", borderRadius: "5px", opacity: selectedUserSize && size && selectedUserSize != size ? "0.2" : "1"}
    }
}

export function getSizeReference(declinations, availableBrandSizes, wantSize) {
    let reference = ""
    let price = 0
    let conditionalOne = false

    if (declinations && declinations.length > 0){
        declinations.forEach(declination => {
            if(declination && declination.analysedRetailers && declination.analysedRetailers.length > 0){
                declination.analysedRetailers.map(retailer => {
                    retailer && retailer.sizes && retailer.sizes.map(retailerSize => {
                        if (wantSize.size == retailerSize && (declination.bestRetailer.price < price || price ==0) ){
                            if (declination.bestRetailer.sizes.includes(wantSize.size)){
                                conditionalOne = true
                                price = declination.bestRetailer.price
                            }
                            reference = declination
                        }
                    })
                })
            }
            if (declination.bestRetailer && declination.bestRetailer.sizes && declination.bestRetailer.sizes.length > 0){
                declination.bestRetailer.sizes.map(retailerSize => {
                    if (wantSize.size == retailerSize && ((declination.bestRetailer.price < price || price ==0) && !conditionalOne) ){
                        price = declination.bestRetailer.price
                        reference = declination
                    }
                })
            }
        })
    }
    if (reference){
        return reference
    }
}

export function getSizesStatus(bestRetailer, othersRetailer, availableBrandSizes, declinations, getSizeReference){
    if (declinations && declinations.length > 0){
        declinations.forEach(declination => {
            if(declination && declination.analysedRetailers && declination.analysedRetailers.length > 0){
                declination.analysedRetailers.map(retailer => {
                    retailer && retailer.sizes && retailer.sizes.map(retailerSize => {
                        availableBrandSizes.forEach(brandSize => {
                            if(retailerSize === "XXL"){
                                retailerSize = "2XL"
                            }
                            if (brandSize === "2XL"){
                                brandSize = "2XL"
                            }
                            if (retailerSize == brandSize.size){
                                brandSize.status = "othersDeclinations"
                            }
                        })
                    })
                })

            }
        })
    }

    if (othersRetailer && othersRetailer.length > 0) {
        othersRetailer.forEach(retailer => {
            if(retailer && retailer.sizes && retailer.sizes.length > 0){
                retailer.sizes.map(retailerSize => {
                    availableBrandSizes.forEach(brandSize => {
                        if(retailerSize === "XXL"){
                            retailerSize = "2XL"
                        }
                        if (brandSize === "2XL"){
                            brandSize = "2XL"
                        }
                        if (retailerSize == brandSize.size){
                            brandSize.status = "available"
                        }
                    })
                })

            }
        })
    }

    if (bestRetailer && bestRetailer.sizes && bestRetailer.sizes.length > 0) {
        bestRetailer.sizes.forEach(size => {
            availableBrandSizes.forEach(brandSize => {
                if(size === "XXL"){
                    size = "2XL"
                }
                if (brandSize === "2XL"){
                    brandSize = "2XL"
                }
                if (brandSize && brandSize.size == size){
                    brandSize.status = "available"
                }
            })
        })
    } else {
        availableBrandSizes.forEach(brandSize => {
            brandSize.status = "available"
        })
    }
}

export function getOthersSizesStatus(availableBrandSizes, declinations){
    if (declinations && declinations.length > 0){
        declinations.forEach(declination => {
            if(declination && declination.analysedRetailers && declination.analysedRetailers.length > 0){
                declination.analysedRetailers.map(retailer => {
                    retailer && retailer.sizes && retailer.sizes.map(retailerSize => {
                        availableBrandSizes.forEach(brandSize => {
                            if(retailerSize === "XXL"){
                                retailerSize = "2XL"
                            }
                            if (brandSize === "2XL"){
                                brandSize = "2XL"
                            }
                            if (retailerSize == brandSize.size){
                                brandSize.status = "othersDeclinations"
                            }
                        })
                    })
                })

            }
        })
    }
}

export function GetAmbassadorAvailableSizes(productType, brand){
    let sizes = []
    if (productType == "/products/shoes" || productType == "/products/spikes"){
        if (brand === "adidas" || brand === "Salomon" || brand == "Hoka One One" || brand == "Hoka"|| productType == "/products/spikes"){
            sizes = getAdidasSizes()
        } else if (brand === "Nike"){
            sizes = getNikeSizes()
        } else {
            sizes = getOtherSizes()
        }
    } else if (productType == "/products/apparel"){
        sizes = getApparelSize()
    }
    return sizes
}

export function checkExistingSize(brand, productType, size){
    if (productType == "/products/shoes" || productType == "/products/spikes"){
        if (brand === "adidas" || brand === "Salomon" || brand == "Hoka One One" || brand == "Hoka"|| productType == "/products/spikes"){
            let sizes = getAdidasSizes()
            if(sizes.indexOf(size) > -1){
                return true
            }
        } else if (brand === "Nike"){
            let sizes = getNikeSizes()
            if(sizes.indexOf(size) > -1){
                return true
            }
        } else {
            let sizes = getOtherSizes()
            if(sizes.indexOf(size) > -1){
                return true
            }
        }
    } else if (productType == "/products/apparel"){
        let sizes = getApparelSize()
        if(sizes.indexOf(size) > -1){
            return true
        }
    }{

    }
    return false
}

export function newCheckExistingSize(productType, size){
    if (productType == "/products" || productType == "/products/shoes" || productType == "/products/spikes"){
        let sizes = getAdidasSizes()
        if(sizes.indexOf(size) > -1){
            return true
        }
        sizes = getNikeSizes()
        if(sizes.indexOf(size) > -1){
            return true
        }
        sizes = getOtherSizes()
        if(sizes.indexOf(size) > -1){
            return true
        }
    } else if (productType == "/products" || productType == "/products/apparel"){
        let sizes = getApparelSize()
        if(sizes.indexOf(size) > -1){
            return true
        }
    }{

    }
    return false
}

export function getAvailablesBrandSizes(brand, productType){
    let sizes = []
    if (productType == "/products/apparel"){
        sizes = getApparelSize()
    }  else {
        if (brand === "adidas" || brand === "Salomon" || brand == "Hoka One One" || brand == "Arc'Teryx") {
            sizes = getAdidasSizes()
        } else if (brand === "Nike"){
            sizes = getNikeSizes()
        } else {
            sizes = getOtherSizes()
        }
    }

    let sizesStatus = []
    sizes.forEach(size => {
        if(size == "XXL"){
            size = "2XL"
        }
        sizesStatus.push({size: size, status: "unavailable"})
    })

    if (productType == "/products/accessories" || productType == "/products/nutrition" ||
        productType == "/products/electronic"  || productType == "/products/vouchers"){
        sizes = getAccesoriesSize()
        sizesStatus = []
        sizes.forEach(size => {
            sizesStatus.push({size: size, status: "available"})
        })
    }

    return sizesStatus
}

export function getAvailablesSizes(headCategory){
    if (headCategory == "/apparel"){
        return getApparelSize()
    } else if (headCategory == "/shoes" || headCategory == "/spikes"){
        return getShoesSize()
    } else if(headCategory == "") {
        return [...getShoesSize(), ...getApparelSize()]
    } else {
        getAccesoriesSize()
    }
}

function getAccesoriesSize(){
    return [
        "TU",
    ]
}

function getApparelSize(){
    return [
        "2XS",
        "XS",
        "S",
        "M",
        "L",
        "XL",
        "2XL"
    ]
}

function getShoesSize(){
    return [
        "35",
        "35½",
        "36",
        "36½",
        "36⅔",
        "37",
        "37½",
        "37⅓",
        "38",
        "38½",
        "38⅔",
        "39",
        "39⅓",
        "39½",
        "40",
        "40½",
        "40⅔",
        "41",
        "41⅓",
        "41½",
        "42",
        "42½",
        "42⅔",
        "43",
        "43⅓",
        "43½",
        "44",
        "44½",
        "44⅔",
        "45",
        "45⅓",
        "45½",
        "46",
        "46½",
        "46⅔",
        "47",
        "47⅓",
        "47½",
        "48",
        "48½",
        "48⅔",
        "49",
        "49⅓",
        "49½",
        "50",
        "50½",
        "50⅔",
        "51",
        "51⅓",
        "51½"
    ]
}

function getAdidasSizes(){
    return [
        "36",
        "36⅔",
        "37⅓",
        "38",
        "38⅔",
        "39⅓",
        "40",
        "40⅔",
        "41⅓",
        "42",
        "42⅔",
        "43⅓",
        "44",
        "44⅔",
        "45⅓",
        "46",
        "46⅔",
        "47⅓",
        "48",
        "48⅔",
        "49⅓",
        "50",
        "50⅔",
        "51⅓"
    ]
}

function getNikeSizes(){
    return [
        "35",
        "35½",
        "36",
        "36½",
        "37½",
        "38",
        "38½",
        "39",
        "40",
        "40½",
        "41",
        "42",
        "42½",
        "43",
        "44",
        "44½",
        "45",
        "45½",
        "46",
        "46½",
        "47",
        "47½",
        "48",
        "48½",
        "49½",
        "50",
        "50½",
        "51",
        "51½"
    ]
}

function getOtherSizes(){
    return [
        "36",
        "36½",
        "37",
        "37½",
        "38",
        "38½",
        "39",
        "39½",
        "40",
        "40½",
        "41",
        "41½",
        "42",
        "42½",
        "43",
        "43½",
        "44",
        "44½",
        "45",
        "45½",
        "46",
        "46½",
        "47",
        "47½",
        "48",
        "48½",
        "49",
        "49½",
        "50",
        "50½",
        "51",
        "51½"
    ]
}