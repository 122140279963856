import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import ProductDescriptionInfo from "../../components/product/ProductDescriptionInfo";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../assets/css/principal-slider-img.scss'
import {useTranslation} from "react-i18next";
import {BrowserView, isMobileOnly} from "react-device-detect";
import ProductDescriptionInfoLaunching from "../../components/product/ProductDescriptionInfoLaunching";
import ReactGA from "react-ga4";
import { getAvailablesBrandSizes } from "../../pages/shop-product/tools";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import PimsterWidget from "../../components/PimsterWidget";
import { TbBrandCarbon } from "react-icons/tb";
import { Divider } from "@mui/material";

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  currentDeclination,
  product,
  currency,
  cartItems,
  wishlistItems,
  compareItems,
  setCurrentDeclination,
  pathname,
  productRating,
  setCurrentTab,
  averageHistory,
  productLaunching,
  productLaunchingRetailers,
  query
}) => {

  const wishlistItem = wishlistItems.filter(
    wishlistItem => wishlistItem.id === currentDeclination.reference
  )[0];
  const compareItem = compareItems.filter(
    compareItem => compareItem.id === currentDeclination.reference
  )[0];

  const handleDragStart = (e) => e.preventDefault();
  const [carouselPrincipalItems, setCarouselPrincipalItems] = useState(null);
  const [carouselSlideInfo, setCarouselSlideInfo] = useState(null);
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0)
  const [carouselSecondaryItems, setCarouselSecondaryItems] = useState(null);
  const [productSizes, setProductSizes] = useState([])
  const [selectedProductDeclination, setSelectedProductDeclination] = useState(
    currentDeclination ? currentDeclination : {}
  );
  const [ratingProductStars, setRatingProductStars] = useState(0);
  const [selectedUserSize, setSelectedUserSize] = useState("")

  useEffect(() => {
    if(productRating && productRating.length > 0) {
        let stars = 0
        productRating.map(rating => {
            stars += rating.stars
        })
        stars = stars / productRating.length
        setRatingProductStars(stars)
    }
  }, [productRating]);

  const scroll = (tab) => {
    const section = document.querySelector( '#othersSizes' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    setCurrentTab(tab);
    return
  };

  const scrollDesktop = (tab) => {
    const section = document.querySelector( '#othersSizesDesktop' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    setCurrentTab(tab)
    return;
  };

  useEffect(() => {
    setProductSizes(getAvailablesBrandSizes(product.brand, product.headCategory))
    let items = []
    if (product.declinations){
        if (product.declinations.length > 0) {
            product.declinations.map((declination, index) => {
                if (declination.reference !== currentDeclination.reference){
                    items.push(
                        <div onClick={() => {
                            setSelectedProductDeclination(declination);
                            setCurrentDeclination(declination);
                            handleClick(pathname, declination.reference)
                            ReactGA.event({
                                category: 'Product',
                                action: 'Change product ' + product.name  + ' color : ' + declination.color
                            });
                        }} style={{width: '70px', paddingRight: '10px'}}>
                            <img style={{width: '100%', borderRadius: "5px"}} src={declination.firstImg} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + declination.reference + " au meilleur prix ! ("+index+")"}/>
                        </div>
                    )
                } else {
                    setActiveIndex(index)
                    items.push(
                        <div onClick={() => {
                            setSelectedProductDeclination(currentDeclination);
                            setCurrentDeclination(currentDeclination);
                            handleClick(pathname, currentDeclination.reference)
                            ReactGA.event({
                                category: 'Product',
                                action: 'Change product ' + product.name  + ' color : ' + currentDeclination.color
                            });
                        }} style={{width: '70px', paddingRight: '10px'}}>
                            <img style={{width: '100%', border: 'solid 1px', borderRadius: "5px"}} src={currentDeclination.firstImg} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + currentDeclination.reference + " au meilleur prix !"}/>
                        </div>
                    )
                }
            })
        }
        setCarouselSecondaryItems(items)
    }
  }, [currentDeclination]);
  const handleClick = (pathname, reference) =>  window.history.replaceState(null, "", [pathname + "?reference=" + reference])

  useEffect(() => {
    let items = []
    if (currentDeclination){
      let itemsImg = []

      if(currentDeclination.firstImg) {
        itemsImg.push(currentDeclination.firstImg)
      }
      if (currentDeclination.imgs && currentDeclination.imgs.length > 0) {
        currentDeclination.imgs.map(img => {
          itemsImg.push(img)
        })
      }
      let itemsDeduplicated =  itemsImg.filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      })
      if (itemsDeduplicated.length > 0) {
        itemsDeduplicated.map((img, index) => {
          items.push( <img style={{width: '70%', marginTop: isMobileOnly ? "-20%" : "-150px"}} src={img} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + currentDeclination.reference + " au meilleur prix ! ("+index+")"}/>)
        })
      }
      setCarouselPrincipalItems(items)
    }
  }, [currentDeclination]);

  const { addToast } = useToasts();

  const finalProductPrice = currentDeclination.price;
  const discountedPrice = currentDeclination.bestRetailer.price;
  const finalDiscountedPrice = +(
      discountedPrice * currency.currencyRate
  ).toFixed(2);

  const discount = Math.round((currentDeclination.price - currentDeclination.bestRetailer.price) / currentDeclination.price * 100)

  useEffect(() => {
    if (discount > 1){
      setCarouselSlideInfo(discount)
    } else {
      setCarouselSlideInfo(null)
    }
  }, [discount]);

  useEffect(() => {
    if (currentDeclination.availableSoon && !currentDeclination.active) {
      setCarouselSlideInfo("Bientôt disponible")
    }
  }, [currentDeclination]);

  const renderSlideInfo = () => {
    if(carouselSlideInfo == "Bientôt disponible"){
      var sheet = document.createElement('style')
      sheet.innerHTML = ".product-image-description .alice-carousel__slide-info {background-color: red !important;}";
      document.body.appendChild(sheet);
      return t('availablesoon')
    } else {
      var sheet = document.createElement('style')
      sheet.innerHTML = ".product-image-description .alice-carousel__slide-info {background-color: black !important;}";
      document.body.appendChild(sheet);
      return `- ` + discount + `%`;
    }
    return ""
  };

  const activeDots = (product) => {
    if(product.declinations.length > 5 && isMobileOnly){
        return false
    } else if (product.declinations.length > 7 && !isMobileOnly) {
        return false
    }
    return true

}

  return (
    <div
      className={`shop-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-5 product-image-description" style={{paddingLeft: isMobileOnly ? "0px": "15px", paddingRight: isMobileOnly ? "0px": "15px", height: isMobileOnly ? "200px" : ""}}>
            {carouselPrincipalItems && carouselPrincipalItems.length > 0 ?
                <div className="maxi-img">
                  <AliceCarousel
                    mouseTracking
                    items={carouselPrincipalItems}
                    disableButtonsControls={true}
                    keyboardNavigation={true}/>
                </div>
                
                : null}
                <BrowserView>
                  <div className="pro-details-size-color">
                      <div className="pro-details-color-wrap">
                          {product.declinations.length == 1 ?
                              <span>{product.declinations.length} {t('availablecolor')} :</span>
                              :
                              <span>{product.declinations.length} {t('availablecolors')} :</span>
                          }
                          <div style={{cursor: 'pointer'}}>
                              {carouselSecondaryItems && carouselSecondaryItems.length > 0 ?
                                  <AliceCarousel
                                      mouseTracking
                                      items={carouselSecondaryItems}
                                      disableButtonsControls={true}
                                      disableDotsControls={activeDots(product)}
                                      autoWidth={true}
                                      activeIndex={activeIndex}
                                  />
                                  : null}
                          </div>
                      </div>
                    </div>
                    <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                    <div className="product-details-content">
                      <h1>{product.name} {product.headCategory == "/products/nutrition" && product.brand != "Maurten" ? " (" + selectedProductDeclination.color + ")" : null}</h1>
                      <div className="pro-details-meta">
                          <span>{t('reference')} </span><span>{selectedProductDeclination.reference}</span>
                      </div>
                      <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                      {
                        product && product.headCategory == "/products/shoes" && (
                            !product.pimsterProductName || product.pimsterProductName == "" || !product.pimsterModuleID  || product.pimsterModuleID == ""
                        )?
                            <>
                                <div>
                                    <Row>
                                        <Col xs={isMobileOnly ? 6 : 4}>
                                            <div className="pro-details-meta">
                                                <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                            </div>
                                            <div className="pro-details-meta">
                                                <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                            </div>
                                        </Col>
                                        <Col xs={isMobileOnly ? 6 : 4}>
                                            <div className="pro-details-meta">
                                                <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                            </div>
                                            <div className="pro-details-meta">
                                                <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider style={{paddingBottom: "5px"}}/>
                                </div>
                                {
                                    (product.pimsterProductName && product.pimsterProductName != "" && product.pimsterModuleID  && product.pimsterModuleID != "") ?
                                    <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div> : null
                                }
                            </>
                        : null
                      }  
                        {
                          product && product.pimsterProductName && product.pimsterProductName != "" && product.pimsterModuleID  && product.pimsterModuleID != "" ?
                              <BrowserView>
                                  <Row style={{marginTop: '10px', marginBottom: "20px"}}>
                                      <Col xs={3}>
                                          <PimsterWidget isProductPage={true} productName={product.pimsterProductName} moduleID={product.pimsterModuleID}/>
                                      </Col>
                                      <Col xs={9}>
                                      {
                                          product && product.headCategory == "/products/shoes" ?
                                              <>
                                                  <div>
                                                      <Row>
                                                          <Col xs={6}>
                                                              <div className="pro-details-meta">
                                                                  <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                                              </div>
                                                              <div className="pro-details-meta">
                                                                  <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                                              </div>
                                                          </Col>
                                                          <Col xs={6}>
                                                              <div className="pro-details-meta">
                                                                  <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                                              </div>
                                                              <div className="pro-details-meta">
                                                                  <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                                              </div>
                                                          </Col>
                                                      </Row>
                                                  </div>
                                              </>
                                          : null
                                      }
                                      </Col>
                                  </Row>
                                  <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                              </BrowserView>
                          : null
                        }
                        {
                          productRating && ratingProductStars > 0 ?
                            <div className="pro-details-meta">
                                <span id="review-rating-stars">{t('clientrate')} ({productRating.length}) : &nbsp;
                                    {(() => {
                                        const rows = [];

                                        for (let i = 0; i < Math.round(ratingProductStars); i++) {
                                            rows.push(<i className="fa fa-star" key={i} style={{color: '#ffa900'}}/>);
                                        }
                                        if(Math.round(ratingProductStars) < 5) {
                                            for (let i = 0; i < Math.round(5-ratingProductStars); i++) {
                                                rows.push(<i className="fa fa-star" key={i} style={{color: 'grey'}}/>);
                                            }
                                        }
                                        return rows;
                                    })()} &nbsp; {ratingProductStars.toFixed(2)}/5
                                </span>
                            </div>
                            :
                            <div className="pro-details-meta">
                                <span style={{cursor: 'pointer'}} onClick={isMobileOnly ? scroll : scrollDesktop}><b>{t('norate')}</b></span>
                            </div>
                        }
                      </div>
                </BrowserView>
          </div>
          <div className="col-lg-7 col-md-7">
            {/* product description info */}
            {
              productLaunching ?
              <ProductDescriptionInfoLaunching
                product={product}
                currentDeclination={currentDeclination}
                discountedPrice={discount}
                currency={currency}
                finalDiscountedPrice={finalDiscountedPrice}
                finalProductPrice={finalProductPrice}
                cartItems={cartItems}
                wishlistItem={wishlistItem}
                compareItem={compareItem}
                addToast={addToast}
                setCurrentDeclination={setCurrentDeclination}
                pathname={pathname}
                productRating={productRating}
                setCurrentTab={setCurrentTab}
                averageHistory={averageHistory}
                setSelectedUserSize={setSelectedUserSize}
                productLaunchingRetailers={productLaunchingRetailers}
              />
              :
              <ProductDescriptionInfo
                product={product}
                currentDeclination={currentDeclination}
                discountedPrice={discount}
                currency={currency}
                finalDiscountedPrice={finalDiscountedPrice}
                finalProductPrice={finalProductPrice}
                cartItems={cartItems}
                wishlistItem={wishlistItem}
                compareItem={compareItem}
                addToast={addToast}
                setCurrentDeclination={setCurrentDeclination}
                pathname={pathname}
                productRating={productRating}
                setCurrentTab={setCurrentTab}
                averageHistory={averageHistory}
                selectedUserSize={selectedUserSize}
                query={query}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  currentDeclination: PropTypes.object,
  pathname: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array,
  othersSizesRef: PropTypes.any,
  productRating: PropTypes.array,
  setCurrentTab: PropTypes.func,
  averageHistory: PropTypes.any,
  setSelectedUserSize: PropTypes.func,
  productLaunching: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData,
  };
};

export default connect(mapStateToProps)(ProductImageDescription);
