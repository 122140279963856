import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import axios from "axios";
import NewsletterProductModal from "./NewsletterProductModal";
import {getSize, getUnavailableSizes} from "../../helpers/sizes";
import ReactGA from "react-ga4";
import {
    getMerchandDeliveryTimeFromName,
    getMerchandLogoFromName,
    getMerchandReturnTimeFromName
} from "../../wrappers/product/ProductDescriptionTab";
import NewsletterPriceModal from "./NewsletterPriceModal";
import {useHistory} from "react-router";
import {isBrowser, isMobileOnly, BrowserView, MobileView} from "react-device-detect";
import AliceCarousel from "react-alice-carousel";
import {getAvailablesBrandSizes, getOthersSizesStatus, getSizeReference, getSizesStatus, getSizeStyle} from "../../pages/shop-product/tools";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import {buildRedirectingLinkByUserID} from "../../helpers/redirecting-user";
import {useTranslation} from "react-i18next";
import {ReactComponent as ScissorsLogo} from '../../assets/img/scissors-svgrepo-com.svg';
import PimsterWidget from "../PimsterWidget";
import { TbBrandCarbon } from "react-icons/tb";
import GenerateAmbassadorLinkModal from "../header/GenerateAmbassadorLinkModal";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { RiRulerLine } from "react-icons/ri";
import { sortSize } from "./ShopSize";

export const redirection = (retailer, origin) => {
    let retailerURL = buildRedirectingLinkByUserID(retailer, origin)
    if (retailer){
        if (retailer.retailerName) {
            switch (retailer.retailerName) {
                case "KellerSports":
                    window.open(retailerURL, '_blank')
                case "Polar":
                    window.open(retailerURL, '_blank')
                case "sportShoes":
                    window.open(retailerURL, "_blank")
                default:
                    window.open('https://app.therunningcollective.'+origin+ "/redirection?redirectionURL="+ decodeURI(retailerURL), '_blank')
            }
        }
    } else {
        window.open('https://app.therunningcollective.'+origin+ + "/redirection?redirectionURL="+ decodeURI(retailerURL), '_blank')
    }
}

export const redirectionWithVoucher = (retailer, origin, voucher, retailerName) => {
    let retailerURL = buildRedirectingLinkByUserID(retailer, origin)
    if (retailer){
        if (retailer.retailerName) {
            switch (retailer.retailerName) {
                case "KellerSports":
                    window.open(retailerURL, '_blank')
                case "Polar":
                    window.open(retailerURL, '_blank')
                case "sportShoes":
                    window.open(retailerURL, "_blank")
                default:
                    window.open('https://app.therunningcollective.'+origin+'/redirection-with-voucher?voucher='+voucher+"&retailerName="+decodeURI(retailerName)+"&redirectionURL="+ decodeURI(retailerURL), '_blank')
            }
        }
    } else {
        window.open('https://app.therunningcollective.'+origin+'/redirection-with-voucher?voucher='+voucher+"&retailerName="+decodeURI(retailerName)+"&redirectionURL="+ decodeURI(retailerURL), '_blank')
    }
}

const ProductDescriptionInfo = ({
  product,
  currentDeclination,
  discountedPrice,
  currency,
  finalDiscountedPrice,
  finalProductPrice,
  setCurrentDeclination,
  pathname,
  productRating,
  setCurrentTab,
  averageHistory,
  query
}) => {
    const [selectedProductDeclination, setSelectedProductDeclination] = useState(
        currentDeclination ? currentDeclination : {}
    );

    const [modalShow, setModalShow] = useState(false);
    const [modalPriceShow, setModalPriceShow] = useState(false);
    const [ratingProductStars, setRatingProductStars] = useState(0);
    const { t } = useTranslation();

    const unavailableSizes = getUnavailableSizes(product, selectedProductDeclination);
    const [selectedUserSize, setSelectedUserSize] = useState(getSize(query, product))

    const [voucherInfo, setVoucherInfo] = useState(null)
    const [productSizes, setProductSizes] = useState([])
    const [avalaibleMerchands, setAvalaibleMerchands] = useState([]);
    const [adidasVoucher, setAdidasVoucher] = useState(null)
    const [showVoucher, setShowVoucher] = useState(false)
    const [carouselPrincipalItems, setCarouselPrincipalItems] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0)
    const handleDragStart = (e) => e.preventDefault();
    const { i18n } = useTranslation();
    const [origin, setOrigin] = useState("fr")
    const [isAmbassador, setIsAmbassador] = useState(false);
    const [openExportAmbassadorProduct, setOpenExportAmbassadorProduct] = useState(false);
    const [expandSizes, setExpandSizes] = useState(false);

    useEffect(() => {
      let token = getClientTokenData();
      if (token && token.isAmbassador){
        setIsAmbassador(true)
      }
    }, [])

    useEffect(() => {
        setOrigin(i18n.language)
    }, [])

    useEffect(() => {
        setProductSizes(getAvailablesBrandSizes(product.brand, product.headCategory))
        let items = []
        if (product.declinations){
            if (product.declinations.length > 0) {
                product.declinations.map((declination, index) => {
                    if (declination.reference !== currentDeclination.reference){
                        items.push(
                            <div onClick={() => {
                                setSelectedProductDeclination(declination);
                                setCurrentDeclination(declination);
                                handleClick(pathname, declination.reference)
                                ReactGA.event({
                                    category: 'Product',
                                    action: 'Change product ' + product.name  + ' color : ' + declination.color
                                });
                            }} style={{width: '70px', paddingRight: '10px'}}>
                                <img style={{width: '100%', borderRadius: "5px"}} src={declination.firstImg} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + declination.reference + " au meilleur prix ! ("+index+")"}/>
                            </div>
                        )
                    } else {
                        setActiveIndex(index)
                        items.push(
                            <div onClick={() => {
                                setSelectedProductDeclination(currentDeclination);
                                setCurrentDeclination(currentDeclination);
                                handleClick(pathname, currentDeclination.reference)
                                ReactGA.event({
                                    category: 'Product',
                                    action: 'Change product ' + product.name  + ' color : ' + currentDeclination.color
                                });
                            }} style={{width: '70px', paddingRight: '10px'}}>
                                <img style={{width: '100%', border: 'solid 1px', borderRadius: "5px"}} src={currentDeclination.firstImg} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + currentDeclination.reference + " au meilleur prix !"}/>
                            </div>
                        )
                    }
                })
            }
            setCarouselPrincipalItems(items)
        }
    }, [currentDeclination]);

    const handleClick = (pathname, reference) =>  window.history.replaceState(null, "", [pathname + "?reference=" + reference])

    const fetchMerchandsData = () => {
        let query = 'https://public-front-api.therunningcollective.fr/merchants'

        axios
            .get(query)
            .then((res) => {
                setAvalaibleMerchands(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const isSelectedUserSize = (size) => {
        if (size == selectedUserSize) {
            return {border: "solid 2px #7ac066", borderRadius: "5px", marginBottom: '5px',}
        }
        return {backgroundColor: "#f1f2f6", borderRadius: "5px", marginBottom: '5px',}
    }

    const generateVoucher = (voucherName) => {
        if (voucherName === "adidastogenerate" || voucherName === "adidastogenerateextra"){
            return adidasVoucher
        } else {
            return voucherName
        }
    }

    if(currentDeclination.analysedRetailers && currentDeclination.analysedRetailers.length > 0){
        getSizesStatus(currentDeclination.bestRetailer, currentDeclination.analysedRetailers, productSizes, product.declinations)
    } else {
        getOthersSizesStatus(productSizes, product.declinations)
    }

    const redirectionOtherDeclination = (productSize, declinations, shortURL, productSizes, setCurrentDeclination) => {
        let declination = getSizeReference(declinations, productSizes, productSize)
        
        if (productSize.status == "othersDeclinations") {
            setCurrentDeclination(declination)
            window.history.replaceState(null, "", [pathname + "?reference=" + declination.reference])
        }
    }

    useEffect(() => {
        fetchMerchandsData();
        if(selectedProductDeclination.bestRetailer.retailerName === "adidas") {

        } else {
            setShowVoucher(true)
        }
    }, [selectedProductDeclination, showVoucher]);

    useEffect(() => {
        if(productRating && productRating.length > 0) {
            let stars = 0
            productRating.map(rating => {
                stars += rating.stars
            })
            stars = stars / productRating.length
            setRatingProductStars(stars)
        }
    }, [productRating]);

    const scroll = (tab) => {
        const section = document.querySelector( '#othersSizes' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        setCurrentTab(tab);
        return
    };

    const scrollDesktop = (tab) => {
        const section = document.querySelector( '#othersSizesDesktop' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        setCurrentTab(tab)
        return;
    };

    const activeDots = (product) => {
        if(product.declinations.length > 5 && isMobileOnly){
            return false
        } else if (product.declinations.length > 7 && !isMobileOnly) {
            return false
        }
        return true

    }

    const renderTooltipBestDeclination = (status) => (
        <Tooltip id="button-tooltip" >
            <div>{
                status == "available" ?
                    <span>{t('seeproductpartener')}</span>
                    :
                    status == "othersRetailers" ?
                        <span>{t('seeotherspartners')}</span> :
                        status == "othersDeclinations" ?
                            <span>{t('seeothersreference')}</span>
                        : <span>{t('nostocksize')}</span>
            }</div>
        </Tooltip>
    );

    useEffect(() => {
        if(currentDeclination && currentDeclination.bestRetailer.retailerName == ""){
            setExpandSizes(true)
        } else {
            setExpandSizes(false)
        }
    }, [currentDeclination])

    const fetchVoucherInfo = (slug, reference, merchandName) => {
        let query = 'https://public-front-api.therunningcollective.fr/voucher-info?productName='+slug+'&reference='+reference+'&merchandName='+merchandName
        axios
            .get(query)
            .then((res) => {
                setVoucherInfo(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
    };

    /*useEffect(() => {
        if(selectedProductDeclination.bestRetailer.retailerName && product.shortURL && selectedProductDeclination.reference && origin == "fr"){
            fetchVoucherInfo(product.shortURL, selectedProductDeclination.reference, selectedProductDeclination.bestRetailer.retailerName)
        }
    }, [origin])*/

    return (
        <div className="product-details-content ml-70">
            {
                isMobileOnly ?
                    <div style={{marginTop: "30px"}}>
                        <h1 style={{display: "none"}}>{product.name} {product.headCategory == "/products/nutrition" && product.brand != "Maurten" ? " (" + selectedProductDeclination.color + ")" : null}</h1>
                        <div className="pro-details-size-color">
                            <div className="pro-details-color-wrap">
                                {product.declinations.length == 1 ?
                                    <span>{product.declinations.length} {t('availablecolor')} :</span>
                                    :
                                    <span>{product.declinations.length} {t('availablecolors')} :</span>
                                }
                                <div style={{cursor: 'pointer'}}>
                                    {carouselPrincipalItems && carouselPrincipalItems.length > 0 ?
                                        <AliceCarousel
                                            mouseTracking
                                            items={carouselPrincipalItems}
                                            disableButtonsControls={true}
                                            disableDotsControls={activeDots(product)}
                                            autoWidth={true}
                                            activeIndex={activeIndex}
                                        />
                                        : null}
                                </div>
                            </div>
                        </div>
                        {
                            product && product.pimsterProductName && product.pimsterProductName != "" && product.pimsterModuleID  && product.pimsterModuleID != "" ?
                                <>
                                    <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}>
                                    </div>
                                    <div style={{marginTop: '10px', marginBottom: "10px"}}>
                                        <PimsterWidget isProductPage={true} productName={product.pimsterProductName} moduleID={product.pimsterModuleID}/>
                                    </div>
                                </>
                            : null
                        }
                        
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}>
                        { 
                            isAmbassador ?
                            <>
                                <div>
                                    <Row>
                                        <Col xs={12}>
                                            <div className="pro-details-quality" style={{display: "block", margin: "0", marginTop: "10px"}}>
                                                <div className="pro-details-cart ml-0" style={{margin: "0"}}>
                                                    <a
                                                        href={null}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                        onClick={() => {
                                                            setOpenExportAmbassadorProduct(!openExportAmbassadorProduct)
                                                            ReactGA.event({
                                                                category: 'Product',
                                                                action: 'Export product ' + product.name
                                                            });
                                                        }}
                                                    >
                                                        Créer un lien ambassadeur
                                                    </a>
                                                </div> 
                                            </div>  
                                        </Col>
                                    </Row>
                                </div>
                            </> : null
                        }
                        </div>
                    </div>
                    : null
            }
            <BrowserView>
                {    
                    isAmbassador ?
                        <>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <div className="pro-details-quality" style={{display: "block", margin: "0", marginTop: "10px"}}>
                                            <div className="pro-details-cart ml-0" style={{margin: "0"}}>
                                                <a
                                                    href={null}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                    onClick={() => {
                                                        setOpenExportAmbassadorProduct(!openExportAmbassadorProduct)
                                                        ReactGA.event({
                                                            category: 'Product',
                                                            action: 'Export product ' + product.name
                                                        });
                                                    }}
                                                >
                                                    Créer un lien ambassadeur
                                                </a>
                                            </div> 
                                        </div>  
                                    </Col>
                                </Row>
                            </div>
                            <div className="pro-details-list"></div>              
                        </> 
                    : null
                }
            
            </BrowserView>
            {
            product.headCategory !== "/products/accessories" &&  product.headCategory !== "/products/nutrition" && product.headCategory !== "/products/electronic" ?
            <Accordion style={{border: "1px solid black", borderRadius: "5px", boxShadow: "none"}} defaultExpanded={currentDeclination && currentDeclination.bestRetailer.retailerName == "" ? true : false} expanded={expandSizes}>
                <AccordionSummary
                    onClick={() => setExpandSizes(!expandSizes)} 
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Row style={{width: "100%"}}>
                        <Col xs={8}>
                            <Typography component="span"><RiRulerLine size={isMobileOnly ? 22 : 30}/> <span style={{fontSize: isMobileOnly ? "15px" : "18px"}}>Sélectionne ta taille</span></Typography>
                        </Col>
                        <Col xs={4}>
                        {
                            selectedUserSize && selectedUserSize !== "" ?
                                <Typography component="span"><span style={{fontSize: isMobileOnly ? "10px" : "16px", padding: "8px 10px 5px 8px", background: "rgb(178, 255, 226)", borderRadius: "10px", color: "rgb(0, 128, 96)"}}>Taille : {selectedUserSize}</span></Typography>
                            : null
                        }
                        </Col>
                    </Row>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <div className="product-details-content">
                        <div className="pro-details-size-color">
                            <div className="pro-details-size">
                                <div className="pro-details-size-content">
                                    {productSizes.map(productSize => {
                                        return (
                                            productSize.status != "unavailable" ?
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={!isMobileOnly ? renderTooltipBestDeclination(productSize.status) : <span></span>}
                                                >
                                                    <a
                                                        className="button-product-size"
                                                        rel="noopener noreferrer"
                                                        href={null}
                                                        target="_blank"
                                                        onClick={() =>{
                                                            if (productSize.status == "othersDeclinations") {
                                                                if (selectedUserSize == productSize.size){
                                                                    setSelectedUserSize("")
                                                                    setExpandSizes(false)
                                                                } else {
                                                                    setSelectedUserSize(productSize.size);
                                                                    setExpandSizes(false)
                                                                    redirectionOtherDeclination(productSize, product.declinations, product.shortURL, productSizes, setCurrentDeclination)
                                                                }
                                                            }
                                                            if (productSize.status == "available") {
                                                                if (selectedUserSize == productSize.size){
                                                                    setSelectedUserSize("");
                                                                    setExpandSizes(false)
                                                                } else {
                                                                    setSelectedUserSize(productSize.size);
                                                                    setExpandSizes(false)
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <label
                                                            className={`pro-details-size-content--single`}
                                                            style={getSizeStyle(productSize.status, productSize.size, selectedUserSize)}
                                                            key={productSize.size}
                                                            onClick={() => {
                                                                if (productSize.status == "othersDeclinations") {
                                                                    if (selectedUserSize == productSize.size){
                                                                        setSelectedUserSize("");
                                                                        setExpandSizes(false)
                                                                    } else {
                                                                        setSelectedUserSize(productSize.size);
                                                                        setExpandSizes(false)
                                                                        redirectionOtherDeclination(productSize, product.declinations, product.shortURL, productSizes, setCurrentDeclination)
                                                                    }
                                                                }
                                                                if (productSize.status == "available") {
                                                                    if (selectedUserSize == productSize.size){
                                                                        setSelectedUserSize("");
                                                                        setExpandSizes(false)
                                                                    } else {
                                                                        setSelectedUserSize(productSize.size);
                                                                        setExpandSizes(false)
                                                                    }
                                                                }
                                                            }
                                                            }
                                                        >
                                                            <span className="size-name">{productSize.size}</span>
                                                        </label>
                                                    </a>
                                                </OverlayTrigger>
                                                :
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={!isMobileOnly ? renderTooltipBestDeclination(productSize.status) : <span></span>}
                                                >
                                                    <a
                                                        className="button-product-size"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        <label
                                                        className={`pro-details-size-content--single`}
                                                        style={getSizeStyle(productSize.status, productSize.size, selectedUserSize)}
                                                        key={productSize.size}
                                                    >
                                                        <span className="size-name">{productSize.size}</span>
                                                    </label>
                                                    </a>

                                                </OverlayTrigger>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Typography>
                    <div className="row" style={{marginTop: "20px", textAlign: "center"}}>
                        {
                            currentDeclination.bestRetailer.retailerName !== "" ?
                            <div className="col-4">
                                <div style={{
                                    display: "flex",
                                    width: "20px",
                                    height: "20px",
                                    border: "solid 2px #7ac066",
                                    margin: "auto",
                                    marginBottom: "5px",
                                    borderRadius: "5px"
                                }}>
                                </div>
                                <div>
                                    <span>{t('instockthispartner')}</span>
                                </div>
                            </div>
                            : null
                        }
                    <div className={currentDeclination.bestRetailer.retailerName !== "" ? "col-4" : "col-6"}>
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px #FDB52F",
                            margin: "auto",
                            marginBottom: "5px",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>En stock dans un autre coloris</span>
                        </div>
                    </div>
                    <div className={currentDeclination.bestRetailer.retailerName !== "" ? "col-4" : "col-6"}>
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px grey",
                            margin: "auto",
                            marginBottom: "5px",
                            opacity: "0.3",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>{t('nostock')}</span>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '10px'}}>
                    <p style={{textAlign: "center"}}>
                        <i className="fa-regular fa-face-frown" style={{color: 'red'}}></i>&nbsp;
                        {t('nostockinyoursize')} &nbsp;<span style={{cursor: "pointer"}} onClick={() => setModalShow(true)}><u>{t('receiveanotification')}</u>
                        </span>
                    </p>
                </div>
                </AccordionDetails>
            </Accordion>
             : null
            }
            {/*<div className="certificate">&nbsp;{product.trainingApproved ?
                <i title={"Produit approuvé pour les entrainements"} style={{color: 'green'}}
                   className="fas fa-running"></i>
                : null
            }
                {product.raceApproved ?
                    <i title={"Produit ayant réalisé un record du monde"} style={{color: 'gold'}}
                       className="fas fa-trophy"></i>
                    : null
                }</div>*/}

            {currentDeclination.bestRetailer.retailerName !== ""
                ?
                <div>
                    {averageHistory <= 0 ?
                        <div style={{color:'green', marginTop: "10px"}} onClick={() => {isMobileOnly ? scroll("historyPrices") : scrollDesktop("historyPrices")}}>
                            {t('decreasepricenow')}&nbsp;
                            <div style={{cursor: "pointer"}}>
                                <b style={{color: 'black'}}><span style={{color: 'red'}}>➔</span>{t('seehistorical')}</b>
                            </div>
                        </div>
                        : averageHistory > 15 ?
                        <div style={{color:'orange', marginTop: "10px"}} onClick={() => {isMobileOnly ? scroll("historyPrices") : scrollDesktop("historyPrices")}}>
                            {t('pricedecrease7days')}&nbsp;
                            <div style={{cursor: "pointer"}}>
                                <b style={{color: 'black'}}><span style={{color: 'red'}}>➔</span>{t('seehistorical')}</b>
                            </div>
                        </div> : null
                    }

                    <div style={{marginBottom: '6px', marginTop: "5px"}}>
                        <p style={{cursor: "pointer"}} onClick={() => setModalPriceShow(true)}><u>{t('toohighforyou')}</u></p>
                    </div>
                </div>
                : null
            }

            {
                currentDeclination.sponsoredRetailer && currentDeclination.sponsoredRetailer.retailerName != "" ?
                    (selectedUserSize && currentDeclination.sponsoredRetailer.sizes.indexOf(selectedUserSize)) > -1 || selectedUserSize == "" ?
                            <div className="container" style={{padding: "10px", border: "3px solid gold", borderRadius: "5px", marginBottom: "20px", marginTop: "20px"}}>
                                <Row>
                                    <Col xs={isMobileOnly ? 12 : 6}>
                                        <img 
                                            src={getMerchandLogoFromName(currentDeclination.sponsoredRetailer.retailerName, avalaibleMerchands)}
                                            style={{width:"100px", marginRight: "20px"}}                                     
                                        />
                                        <span style={{padding: "8px 10px 5px 8px", background: "gold", borderRadius: "10px", color: "black"}}>Sponsorisé</span>
                                        {
                                            currentDeclination.sponsoredRetailer.amount > 2 ?
                                            <span style={{padding: "8px 10px 5px 8px", marginLeft: "20px", background: "black", borderRadius: "10px", color: "white"}}>- {currentDeclination.sponsoredRetailer.amount}%</span>
                                            : null
                                        }
                                        <div style={{paddingLeft: "5px"}}>
                                            <b style={{fontSize: "16px"}}>Prix : </b>
                                            {
                                            currentDeclination.sponsoredRetailer.amount > 2 ?
                                                <>
                                                    <span style={{color: "rgb(79, 160, 166)", fontSize: "18px"}}>{currentDeclination.sponsoredRetailer.price}€</span>
                                                    <span style={{color: "rgb(126, 126, 126)", paddingLeft: "5px", fontSize: "14px", textDecoration: "line-through"}}>{currentDeclination.price}€</span>
                                                </>
                                                : 
                                                <span style={{color: "rgb(79, 160, 166)", fontSize: "18px"}}>{currentDeclination.sponsoredRetailer.price > 0 ? currentDeclination.sponsoredRetailer.price : currentDeclination.price}€</span>
                                            }
                                        </div>
                                        <div style={{paddingLeft: "5px"}}>
                                            <b style={{fontSize: "16px"}}>Délais de livraisons :</b> &nbsp;
                                            <span style={{color: "rgb(126, 126, 126)"}}>
                                                {
                                                    getMerchandDeliveryTimeFromName(currentDeclination.sponsoredRetailer.retailerName, avalaibleMerchands, t, i18n)
                                                }
                                            </span>
                                            
                                        </div>
                                    </Col>
                                    <Col xs={isMobileOnly ? 12 : 6}>
                                        <Row>
                                            {
                                                currentDeclination.sponsoredRetailer.vouchers && currentDeclination.sponsoredRetailer.vouchers.length === 1 ? 
                                                <>
                                                <Col xs={6} onClick={() => {generateVoucher(currentDeclination.sponsoredRetailer.vouchers[0].name)}}>
                                                  <div style={{
                                                    borderRadius: "5px",
                                                    padding: "10px 10px 5px 10px",
                                                    color: "#4fa0a6",
                                                    fontSize: "18px",
                                                    border: "3px dashed #4fa0a6",
                                                    borderImage: "https://img.therunningcollective.fr/scissors_01-svg",
                                                    textAlign: "center",
                                                    marginTop: "5px"
                                                  }}>
                                                    <div style={{
                                                      float: "left",
                                                      padding: "0px",
                                                      margin: "-21px 0px -10px",
                                                      background: "#fff",
                                                      fontSize: "13px"
                                                    }}><ScissorsLogo style={{height: "20px",
                                                      width: "20px",
                                                      transform: "rotate(90deg)"}}/></div>
                                                    {generateVoucher(currentDeclination.sponsoredRetailer.vouchers[0].name)}
                                                  </div>
                                                </Col>
                                              </> :
                                                <Col xs={6}></Col>
                                            }
                                            <Col xs={isMobileOnly && !currentDeclination.sponsoredRetailer.vouchers ? 12 : 6}>
                                                <div className="pro-details-quality">
                                                  <div className="pro-details-cart ml-0" style={{margin: "0"}}>
                                                    <a
                                                        href={null}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        style={{borderRadius: "5px",
                                                          width: currentDeclination.sponsoredRetailer.showVoucher  && currentDeclination.sponsoredRetailer.vouchers && currentDeclination.sponsoredRetailer.vouchers.length === 1 ? "100%" : "100%"}}
                                                        onClick={() => {
                                                          if(currentDeclination.sponsoredRetailer.vouchers && currentDeclination.sponsoredRetailer.vouchers.length === 1){
                                                            redirectionWithVoucher(currentDeclination.sponsoredRetailer, origin, currentDeclination.sponsoredRetailer.vouchers[0].name, currentDeclination.sponsoredRetailer.retailerName)
                                                          } else {
                                                            redirection(currentDeclination.sponsoredRetailer, origin)
                                                          }
                                                          ReactGA.event({
                                                            category: 'Product',
                                                            action: 'Redirect to ' + currentDeclination.sponsoredRetailer.retailerName
                                                          });
                                                          ReactGA.event({
                                                            category: 'Product',
                                                            action: 'Redirect to ' + currentDeclination.sponsoredRetailer.retailerName + " for product : " + product.name
                                                          });
                                                          ReactPixel.track('AddToCart', {
                                                            content_name: product.name,
                                                            content_type: 'product',
                                                            content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                            content_ids: currentDeclination.reference,
                                                            value: currentDeclination.sponsoredRetailer.price,
                                                            currency: 'EUR',
                                                          })
                                                        }}
                                                    >
                                                      Voir l'offre
                                                    </a>
                                                  </div>
                                                </div>
                                              </Col>
                                              
                                        </Row>   
                                        {
                                           currentDeclination.sponsoredRetailer.vouchers && currentDeclination.sponsoredRetailer.vouchers.length === 1 ?
                                            <div 
                                                style={{
                                                    color: "#4fa0a6",
                                                    textAlign: "center"
                                                }}
                                            >
                                                N'oubliez pas d'utiliser le code de réduction pour bénéficier de cette offre.
                                            </div>  
                                           : null
                                        }
                                                                  
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        {
                                            product.headCategory !== "/products/accessories" &&  product.headCategory !== "/products/nutrition" && product.headCategory !== "/products/electronic" && 
                                            currentDeclination.sponsoredRetailer.sizes && currentDeclination.sponsoredRetailer.sizes.length > 0 ?
                                                <div className="description-review-bottom" style={{padding: "0"}}>
                                                    <div className="product-anotherinfo-wrapper">
                                                        <div className="pro-details-size-content">
                                                            <div style={{fontSize: "16px", paddingLeft: "5px"}}>
                                                                <b>Tailles en stock :</b>
                                                            </div>
                                                            {
                                                                currentDeclination.sponsoredRetailer.sizes ?
                                                                    sortSize(currentDeclination.sponsoredRetailer.sizes).map((singleSize, key) => {
                                                                    if (singleSize !== "Votretaille" && singleSize !== "") {
                                                                        return (
                                                                            <a
                                                                                href={null}
                                                                                style={{cursor: "pointer !important"}}
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                onClick={() => {
                                                                                if(currentDeclination.sponsoredRetailer.showVoucher  && currentDeclination.sponsoredRetailer.vouchers && currentDeclination.sponsoredRetailer.vouchers.length === 1){
                                                                                    redirectionWithVoucher(currentDeclination.sponsoredRetailer, origin, currentDeclination.sponsoredRetailer.vouchers[0].name, currentDeclination.sponsoredRetailer.retailerName)
                                                                                } else {
                                                                                    redirection(currentDeclination.sponsoredRetailer, origin)
                                                                                }
                                                                                ReactGA.event({
                                                                                    category: 'Product',
                                                                                    action: 'Redirect to ' + currentDeclination.sponsoredRetailer.retailerName
                                                                                });
                                                                                ReactGA.event({
                                                                                    category: 'Product',
                                                                                    action: 'Redirect to ' + currentDeclination.sponsoredRetailer.retailerName + " for product : " + product.name
                                                                                });
                                                                                ReactGA.event({
                                                                                    category: 'Product',
                                                                                    action: 'Redirect to ' + currentDeclination.sponsoredRetailer.retailerName + " for product : " + product.name + " by size " + singleSize
                                                                                });
                                                                                ReactPixel.track('AddToCart', {
                                                                                    content_name: product.name,
                                                                                    content_type: 'product',
                                                                                    content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                                                    content_ids: currentDeclination.reference,
                                                                                    value: currentDeclination.sponsoredRetailer.price,
                                                                                    currency: 'EUR',
                                                                                });
                                                                                }}
                                                                            >
                                                                            <label
                                                                                style={{}}
                                                                                className={`pro-details-size-content--single`}
                                                                                style={isSelectedUserSize(singleSize)}
                                                                                key={key}
                                                                            >
                                                                                <div className="size-name" style={{paddingTop: "10px"}}>{singleSize == "XXL" ? "2XL" : singleSize}</div>
                                                                            </label>
                                                                            </a>
                                                                        );
                                                                    } else { return null }
                                                                    })
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                : null
                                            }
                                    </Col>
                                </Row>
                            </div>
                                            : null
                : null
            }

            {
                currentDeclination.analysedRetailers && currentDeclination.analysedRetailers.length > 0 ?
                currentDeclination.analysedRetailers.sort((a, b) => a.price > b.price).map(retailer => {
                    if ((selectedUserSize && retailer.sizes.indexOf(selectedUserSize)) > -1 || selectedUserSize == ""){
                        return(
                            <div className="container" style={{padding: "10px", border: "1px solid black", borderRadius: "5px", marginBottom: "20px"}}>
                                <Row>
                                    <Col xs={isMobileOnly ? 12 : 6}>
                                        <img 
                                            src={getMerchandLogoFromName(retailer.retailerName, avalaibleMerchands)}
                                            style={{width:"100px", marginRight: "20px"}}                                     
                                        />
                                        <span style={{padding: "8px 10px 5px 8px", background: "rgb(178, 255, 226)", borderRadius: "10px", color: "rgb(0, 128, 96)"}}>En stock</span>
                                        {
                                            retailer.amount > 2 ?
                                            <span style={{padding: "8px 10px 5px 8px", marginLeft: "20px", background: "black", borderRadius: "10px", color: "white"}}>- {retailer.amount}%</span>
                                            : null
                                        }
                                        <div style={{paddingLeft: "5px"}}>
                                            <b style={{fontSize: "16px"}}>Prix : </b>
                                            {
                                            retailer.amount > 2 ?
                                                <>
                                                    <span style={{color: "rgb(79, 160, 166)", fontSize: "18px"}}>{retailer.price}€</span>
                                                    <span style={{color: "rgb(126, 126, 126)", paddingLeft: "5px", fontSize: "14px", textDecoration: "line-through"}}>{currentDeclination.price}€</span>
                                                </>
                                                : 
                                                <span style={{color: "rgb(79, 160, 166)", fontSize: "18px"}}>{retailer.price}€</span>
                                            }
                                        </div>
                                        <div style={{paddingLeft: "5px"}}>
                                            <b style={{fontSize: "16px"}}>Délais de livraisons :</b> &nbsp;
                                            <span style={{color: "rgb(126, 126, 126)"}}>
                                                {
                                                    getMerchandDeliveryTimeFromName(retailer.retailerName, avalaibleMerchands, t, i18n)
                                                }
                                            </span>
                                            
                                        </div>
                                    </Col>
                                    <Col xs={isMobileOnly ? 12 : 6}>
                                        <Row>
                                            {
                                                retailer.vouchers && retailer.vouchers.length === 1 ? 
                                                <>
                                                <Col xs={6} onClick={() => {generateVoucher(retailer.vouchers[0].name)}}>
                                                  <div style={{
                                                    borderRadius: "5px",
                                                    padding: "10px 10px 5px 10px",
                                                    color: "#4fa0a6",
                                                    fontSize: "18px",
                                                    border: "3px dashed #4fa0a6",
                                                    borderImage: "https://img.therunningcollective.fr/scissors_01-svg",
                                                    textAlign: "center",
                                                    marginTop: "5px"
                                                  }}>
                                                    <div style={{
                                                      float: "left",
                                                      padding: "0px",
                                                      margin: "-21px 0px -10px",
                                                      background: "#fff",
                                                      fontSize: "13px"
                                                    }}><ScissorsLogo style={{height: "20px",
                                                      width: "20px",
                                                      transform: "rotate(90deg)"}}/></div>
                                                    {generateVoucher(retailer.vouchers[0].name)}
                                                  </div>
                                                </Col>
                                              </> :
                                                <Col xs={6}></Col>
                                            }
                                            <Col xs={isMobileOnly && !retailer.vouchers ? 12 : 6}>
                                                <div className="pro-details-quality">
                                                  <div className="pro-details-cart ml-0" style={{margin: "0"}}>
                                                    <a
                                                        href={null}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        style={{borderRadius: "5px",
                                                          width: retailer.showVoucher  && retailer.vouchers && retailer.vouchers.length === 1 ? "100%" : "100%"}}
                                                        onClick={() => {
                                                          if(retailer.vouchers && retailer.vouchers.length === 1){
                                                            redirectionWithVoucher(retailer, origin, retailer.vouchers[0].name, retailer.retailerName)
                                                          } else {
                                                            redirection(retailer, origin)
                                                          }
                                                          ReactGA.event({
                                                            category: 'Product',
                                                            action: 'Redirect to ' + retailer.retailerName
                                                          });
                                                          ReactGA.event({
                                                            category: 'Product',
                                                            action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name
                                                          });
                                                          ReactPixel.track('AddToCart', {
                                                            content_name: product.name,
                                                            content_type: 'product',
                                                            content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                            content_ids: currentDeclination.reference,
                                                            value: retailer.price,
                                                            currency: 'EUR',
                                                          })
                                                        }}
                                                    >
                                                      Voir l'offre
                                                    </a>
                                                  </div>
                                                </div>
                                              </Col>
                                              
                                        </Row>   
                                        {
                                           retailer.vouchers && retailer.vouchers.length === 1 ?
                                            <div 
                                                style={{
                                                    color: "#4fa0a6",
                                                    textAlign: "center"
                                                }}
                                            >
                                                N'oubliez pas d'utiliser le code de réduction pour bénéficier de cette offre.
                                            </div>  
                                           : null
                                        }
                                                                  
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        {
                                            product.headCategory !== "/products/accessories" &&  product.headCategory !== "/products/nutrition" && product.headCategory !== "/products/electronic" ?
                                                <div className="description-review-bottom" style={{padding: "0"}}>
                                                    <div className="product-anotherinfo-wrapper">
                                                        <div className="pro-details-size-content">
                                                            <div style={{fontSize: "16px", paddingLeft: "5px"}}>
                                                                <b>Tailles en stock :</b>
                                                            </div>
                                                            {
                                                                retailer.sizes ?
                                                                    sortSize(retailer.sizes).map((singleSize, key) => {
                                                                    if (singleSize !== "Votretaille" && singleSize !== "") {
                                                                        return (
                                                                            <a
                                                                                href={null}
                                                                                style={{cursor: "pointer !important"}}
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                onClick={() => {
                                                                                if(retailer.showVoucher  && retailer.vouchers && retailer.vouchers.length === 1){
                                                                                    redirectionWithVoucher(retailer, origin, retailer.vouchers[0].name, retailer.retailerName)
                                                                                } else {
                                                                                    redirection(retailer, origin)
                                                                                }
                                                                                ReactGA.event({
                                                                                    category: 'Product',
                                                                                    action: 'Redirect to ' + retailer.retailerName
                                                                                });
                                                                                ReactGA.event({
                                                                                    category: 'Product',
                                                                                    action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name
                                                                                });
                                                                                ReactGA.event({
                                                                                    category: 'Product',
                                                                                    action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name + " by size " + singleSize
                                                                                });
                                                                                ReactPixel.track('AddToCart', {
                                                                                    content_name: product.name,
                                                                                    content_type: 'product',
                                                                                    content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                                                    content_ids: currentDeclination.reference,
                                                                                    value: retailer.price,
                                                                                    currency: 'EUR',
                                                                                });
                                                                                }}
                                                                            >
                                                                            <label
                                                                                style={{}}
                                                                                className={`pro-details-size-content--single`}
                                                                                style={isSelectedUserSize(singleSize)}
                                                                                key={key}
                                                                            >
                                                                                <div className="size-name" style={{paddingTop: "10px"}}>{singleSize == "XXL" ? "2XL" : singleSize}</div>
                                                                            </label>
                                                                            </a>
                                                                        );
                                                                    } else { return null }
                                                                    })
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                : null
                                            }
                                    </Col>
                                </Row>
                            </div>
                            
                        )
                    }
                    
                })
                : null
            }

            <MobileView>
            {
                product && product.headCategory == "/products/shoes" ?
                    <>
                        <div>
                            <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                            <Row>
                                <Col xs={isMobileOnly ? 6 : 3}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                    </div>
                                </Col>
                                <Col xs={isMobileOnly ? 6 : 4}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                    </>
                : null
            }   
            </MobileView>

            <div className="pro-details-meta" style={{display: "none"}}>
                <span>{t('lastupdate')} {product.updatedDate}</span>
            </div>
            <div id="othersSizesDesktop"></div>


            {/* product modal */}
            <NewsletterProductModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={product}
                unavailableSizes={unavailableSizes}
                selectedProductDeclination={selectedProductDeclination}
                addtocart={addToCart}
                addtowishlist={addToWishlist}
                addtocompare={addToCompare}
            />

            {/* product modal */}
            <NewsletterPriceModal
                show={modalPriceShow}
                onHide={() => setModalPriceShow(false)}
                product={product}
                unavailableSizes={unavailableSizes}
                selectedProductDeclination={selectedProductDeclination}
                addtocart={addToCart}
                addtowishlist={addToWishlist}
                addtocompare={addToCompare}
            />

            {
                isAmbassador && openExportAmbassadorProduct ?
                    <GenerateAmbassadorLinkModal open={openExportAmbassadorProduct} setOpen={setOpenExportAmbassadorProduct} currentLink={window.location.href}/>
                : null
            }
        </div>
    );
};

ProductDescriptionInfo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  addToast: PropTypes.func,
  cartItems: PropTypes.array,
  compareItem: PropTypes.array,
  currency: PropTypes.object,
  currentDeclination: PropTypes.object,
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.object,
  wishlistItem: PropTypes.object,
  setCurrentDeclination: PropTypes.func,
    othersSizesRef: PropTypes.any,
    productRating: PropTypes.array,
    setCurrentTab: PropTypes.func,
    averageHistory: PropTypes.any,
    setSelectedUserSize: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

function getHexaColorFromName(colorName, colors){
  if (colors && colors.length > 0) {
    let hexaColor = colors.filter(color => { return color.name === colorName})[0].hexaColor
    return rgbToHex(hexaColor.r, hexaColor.g, hexaColor.b)
  }
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}


export function setCookie(cName, cValue, expHours) {
    let date = new Date();
    date.setTime(date.getTime() + (expHours * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

export function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

export default connect(null, mapDispatchToProps)(ProductDescriptionInfo);
